import { Option, Select } from 'components/atoms/Select';
import {
  PageSelectContainer,
  Pagination,
  PaginationItem,
  PaginationWrapper,
  TurnPage,
} from 'components/atoms/Pagination';
import {
  RiArrowDropDownFill,
  RiArrowDropUpFill,
  RiArrowLeftSLine,
  RiArrowRightSLine,
} from 'react-icons/ri';
import { downloadCSV, encodeCSV } from 'utils/csvExport';
import { usePagination, useSortBy, useTable } from 'react-table';

import { Button } from 'components/atoms/Buttons';
import { Paragraph } from './Text';
import { sentenceCase } from 'utils/utils';
/* eslint-disable react/jsx-key */
import styled from 'styled-components';

const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
  border-radius: ${({theme}) => theme.glassEffect.tableBorderRadius};
  border-bottom: ${({theme}) => `0.2rem solid ${theme.colors.text}`};
  background-color: ${({theme}) => theme.colors.glassBackground};
  overflow-x: scroll;
  @media screen and (min-width: ${({theme}) =>
          theme.breakpoints.sm.minWidth}) {
    overflow-x: hidden;
    overflow-y: ${({$hide}) => ($hide ? 'hidden' : 'scroll')};
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #a3a3a3;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #868686;
  }
`;
const STable = styled.table`
  text-align: left;
  position: relative;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
`;
const THead = styled.thead`
  position: relative;
`;
const TBody = styled.tbody``;
const TH = styled.th`
  font-size: 1.4rem;
  min-width: 10rem;
  max-width: 15rem;
  background-color: ${({ theme }) => theme.colors.tableHead};
  color: ${({ theme }) => theme.colors.text};
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'initial')};
  left: ${({ $sticky }) => ($sticky ? 0 : 'initial')};
  z-index: ${({ $sticky }) => ($sticky ? 25 : 'initial')};

  &:first-of-type {
    border-radius-top-left: 0.5rem;
    -webkit-border-top-left-radius: 0.5rem;
  }

  &:last-of-type {
    border-radius-top-right: 0.5rem;
    -webkit-border-top-right-radius: 0.5rem;
  }
`;
const TR = styled.tr`
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'initial')};
  top: ${({ $sticky }) => ($sticky ? 0 : 'initial')};
  z-index: ${({ $sticky }) => ($sticky ? 20 : 'initial')};
  border-bottom: thin solid rgba(255, 255, 255, 0.05);
  vertical-align: ${({ $headerRow }) => ($headerRow ? 'bottom' : 'initial')};
`;
const TD = styled.td`
    padding: 5px;
    padding-left: 1rem
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ $sticky, theme }) =>
      $sticky ? theme.colors.tableBackground : 'inherit'};
    position: ${({ $sticky }) => ($sticky ? 'sticky' : 'initial')};
    left: ${({ $sticky }) => ($sticky ? 0 : 'initial')};
    z-index: ${({ $sticky }) => ($sticky ? 10 : 'initial')};
`;

const BorderBottom = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ $color, theme }) =>
    $color ? theme.colors[$color] : 'transparent'};
  z-index: ${({ $sticky }) => ($sticky ? 25 : 'initial')};
`;

const THInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.glassBackground};
`;

const THInnerTextWrapper = styled.div`
  flex: 1;
  padding-left: 1rem !important;
  padding: 0.5rem;
`;

const FullTable = styled.div`
  height: 100%;
  box-shadow: ${({ theme }) => theme.colors.shadow};
`;

const PAGE_SIZE = 12;

const Table = ({
  data,
  columns,
  stackingFrequency,
  displayFrequency,
  setDisplayFrequency,
}) => {
  const tableInstance = useTable(
    { data, columns, initialState: { pageIndex: 0, pageSize: PAGE_SIZE } },
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const numberPagesArray = [PAGE_SIZE, 50, 100].filter((size) => {
    if (size <= data.length) return true;
  });

  return (
    <FullTable>
      <TableContainer style={{
        overflow: 'auto',
        height: data.length >= 12 ? 'calc(100% - 50px)' : 'auto'
      }} $hide={pageSize && pageSize <= PAGE_SIZE}>
        <STable>
          <THead>
            {headerGroups.map((headerGroup) => (
              <TR
                {...headerGroup.getHeaderGroupProps()}
                $sticky={true}
                $headerRow
              >
                {headerGroup.headers.map((column) => (
                  <TH
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    $sticky={column.sticky}
                  >
                    <THInner>
                      <THInnerTextWrapper>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <RiArrowDropUpFill
                                height="17px"
                                width="17px"
                                alt="Sort Desc"
                              />
                            ) : (
                              <RiArrowDropDownFill
                                height="17px"
                                width="17px"
                                alt="Sort Asc"
                              />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </THInnerTextWrapper>
                      <BorderBottom
                        $color={column.color}
                        $sticky={column.sticky}
                      />
                    </THInner>
                  </TH>
                ))}
              </TR>
            ))}
          </THead>
          <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TR {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const sticky = cell.column.sticky;
                    return (
                      <TD $sticky={sticky} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TD>
                    );
                  })}
                </TR>
              );
            })}
          </TBody>
        </STable>
      </TableContainer>
      <PaginationWrapper flex $sticky={true}>
        <Pagination>
          <PaginationItem>
            <Select
              value={displayFrequency}
              onChange={(e) => {
                setDisplayFrequency(e.target.value);
              }}
            >
              {['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']
                .filter((item, idx, array) => {
                  if (idx >= array.indexOf(stackingFrequency)) return true;
                  else return false;
                })
                .map((freq) => (
                  <Option key={freq} value={freq}>
                    {sentenceCase(
                      freq.replace(/ly/gi, '').replace(/i$/gi, 'y')
                    )}
                  </Option>
                ))}
            </Select>
            <Button
              onClick={() => {
                const csvContent = encodeCSV(data, columns);
                downloadCSV(csvContent);
              }}
            >
              Export to CSV
            </Button>
          </PaginationItem>

          <PaginationItem>
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {numberPagesArray.map((pageSize) => {
                const display = pageSize;
                return (
                  <Option key={pageSize} value={pageSize}>
                    Show {display}
                  </Option>
                );
              })}
              <Option key="All" value={data.length}>
                Show All
              </Option>
            </Select>

            <PageSelectContainer>
              <TurnPage
                onClick={previousPage}
                disabled={!canPreviousPage}
                aria-label="Previous Page"
              >
                <RiArrowLeftSLine />
              </TurnPage>
              <Paragraph $padding="initial">{pageIndex + 1}</Paragraph>
              <TurnPage
                onClick={nextPage}
                disabled={!canNextPage}
                aria-label="Next Page"
              >
                <RiArrowRightSLine />
              </TurnPage>
            </PageSelectContainer>
          </PaginationItem>
        </Pagination>
      </PaginationWrapper>
    </FullTable>
  );
};

export default Table;
