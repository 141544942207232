import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: initial;
  margin: initial;
  margin-bottom: 1.5rem;
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'initial')};
  font-size: ${({ theme }) => theme.typography.desktopFontSize.title};
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    font-size: ${({ theme }) => theme.typography.mobileFontSize.title};
  }
  color: ${({ theme, $brand }) =>
    $brand ? theme.colors.primary : theme.colors.text};
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: initial;
  margin: initial;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '1.5rem'};
  font-size: ${({ theme }) => theme.typography.fontSizes[2]};
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'initial')};
  left: ${({ $sticky }) => ($sticky ? 0 : 'initial')};
  color: ${({ theme, $brand }) =>
    $brand ? theme.colors.primary : theme.colors.text};
`;

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: initial;
  margin: initial;
  margin-bottom: initial;
  color: ${({ $color }) => ($color ? $color : 'inherit')};
  font-size: ${({ theme }) => theme.typography.fontSizes[4]};
`;

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: ${({ $padding }) => ($padding ? $padding : 'initial')};
  margin: initial;
  margin-bottom: initial;
  color: ${({ theme, $brand }) =>
    $brand ? theme.colors.primary : theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes[5]};

  display: ${({ $ellipsis }) => $ellipsis && '-webkit-box'};
  -webkit-line-clamp: ${({ $ellipsis }) => $ellipsis && 2};
  -webkit-box-orient: ${({ $ellipsis }) => $ellipsis && 'vertical'};
  overflow: ${({ $ellipsis }) => $ellipsis && 'hidden'};
  text-overflow: ${({ $ellipsis }) => $ellipsis && 'ellipsis'};
  min-height: ${({ $ellipsis }) => $ellipsis && '3.4rem'};
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};
  padding: ${({ $padding }) => ($padding ? $padding : '2rem 0')};
  margin: initial;
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 'initial')};
  font-size: ${({ theme }) => theme.typography.fontSizes[5]};
  line-height: calc(
    ${({ theme, $lineHeight }) =>
        $lineHeight ? $lineHeight : theme.typography.fontSizes[5]} * 2
  );
  color: ${({ theme, $brand, $color }) =>
    $brand ? theme.colors.primary : theme.colors[$color || 'text']};

  display: ${({ $ellipsis }) => $ellipsis && '-webkit-box'};
  -webkit-line-clamp: ${({ $ellipsis }) => $ellipsis && 4};
  -webkit-box-orient: ${({ $ellipsis }) => $ellipsis && 'vertical'};
  overflow: ${({ $ellipsis }) => $ellipsis && 'hidden'};
  text-overflow: ${({ $ellipsis }) => $ellipsis && 'ellipsis'};
  min-height: ${({ $ellipsis }) => $ellipsis && '9.7rem'};
`;

export const Summary = styled.div`
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'center')};
  max-width: 100vw;
  overflow-wrap: break-word;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    font-size: ${({ theme }) => theme.typography.fontSizes[3]};
  }
`;

export const CardParagraph = styled.p`
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  padding: initial;
  margin: initial;
  font-size: ${({ theme }) => theme.typography.fontSizes[5]};
  line-height: calc(${({ theme }) => theme.typography.fontSizes[5]} * 1.25);
  color: ${({ $color, theme }) => ($color ? $color : theme.colors.text)};
`;

export const FooterLink = styled.a`
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  color: ${({ theme, $brand }) =>
    $brand ? theme.colors.linkColours.alt : theme.colors.text};

  &:hover {
    color: ${({ theme, $brand }) =>
      $brand ? theme.colors.linkColours.altHover : theme.colors.text};
  }
`;

export const FooterText = styled.div`
  text-align: center;
  max-width: 800px;
  line-height: 1.2;
  a {
    font-family: ${({ theme }) => theme.typography.bodyFontFamily};
    color: ${({ theme }) => theme.colors.primary};
  }
`;
