import { Box } from 'atomic-layout';
import styled from 'styled-components';

export const PaginationWrapper = styled(Box)`
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'initial')};
  left: ${({ $sticky }) => ($sticky ? 0 : 'initial')};
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    width: 100%;
    margin: auto;
  }
`;

export const Pagination = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.glassEffect.borderRadius} ${theme.glassEffect.borderRadius}`};
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  background: ${({ theme }) => theme.glassEffect.background};
  width: 100%;
  min-height: 4rem;
  box-sizing: border-box;
`;

export const PaginationItem = styled(Box)`
  display: flex;
  gap: 2rem;
`;

export const PageSelectContainer = styled(Box)`
  background: ${({ theme }) => theme.colors.glassSelectBackground};
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  color: ${({ theme }) => theme.colors.text};
  padding: 0rem 0;
  min-height: 3.5rem;
  border: none;
`;

export const TurnPage = styled.button`
  border: none;
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 2rem;
    width: 2rem;
  }

  g {
    fill: ${({ theme, disabled }) =>
      disabled ? `${theme.colors.text}20` : theme.colors.text};
  }
`;
