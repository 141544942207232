import styled from 'styled-components';

export const Select = styled.select`
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  background: ${({ theme }) => theme.colors.glassSelectBackground};
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  padding: 0.2rem 0.5rem;
  min-height: 3.5rem;
  height: 4rem;
  -webkit-appearance: none;
  border: none;

  &:focus-visible {
    outline: none;
  }

  > option {
    background: ${({ theme }) => theme.colors.tableBackground};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Option = styled.option`
  text-transform: capitalize;
`;
