


export const encodeCSV = (rows, columns) => {
    return [
        columns.map(({ accessor, Header }) => `"${Header || accessor}"`).join(","),
        ...rows.map(row => {
            return columns.map(({ accessor, Cell }) => {
                const value = row[accessor];
                if (Cell) return `"${Cell({ value })}"`;
                else return `"${value}"`;
            }).join(",");
        }),
    ].join("\n");
}

export const downloadCSV = (csvContent, filename = "SatsGoal_Data.csv") => {
    var encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    var link = document.createElement("a");
    link.style.display = 'none';
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
}
